<template>
  <div>
    <div class="editable-input flex justify-between relative gap-0">
      <div
        ref="editable"
        class="input placeholder"
        :data-placeholder="placeholder"
        contenteditable="true"
        v-html="displayValue"
        @keyup="keyUpSearch"
        @keypress="keypress"
        @clear="keyUpSearch"
      />

      <el-tooltip
        v-if="error && invalidateMessage"
        class="alert absolute"
        effect="dark"
        :content="invalidateMessage"
        placement="bottom"
      >
        <el-button
          type="warning"
          icon="el-icon-warning-outline"
          size="mini"
        ></el-button>
      </el-tooltip>

      <button
        class="search-btn el-button el-button--default el-button--mini w-4 flex justify-center items-center"
        type="button"
      >
        <i class="el-icon-search" />
      </button>
    </div>
  </div>
</template>

<script>
import { stringToSlug } from "@/plugins/common";

export default {
  props: {
    modelValue: {
      type: [String],
      default: "",
    },
    invalidateMessage: {
      type: [String],
      default: "",
    },
    placeholder: {
      type: [String],
      default: "Nhập ...",
    },
  },

  data() {
    return {
      typingTimer: null,
      error: false,
    };
  },

  mounted() {
    if (this.text.trim() !== "") {
      this.$refs.editable.classList.remove("placeholder");
    } else {
      this.$refs.editable.classList.add("placeholder");
    }
  },
  methods: {
    keyUpSearch(e) {
      const whiteListKeyboard = [
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown',
      ]
      if (whiteListKeyboard.indexOf(e.code) != -1) {
        return;
      }

      this.removeHighlights();
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.doneTyping();
      }, 300);
    },

    keypress(e) {
      if (e.code == 'Enter') {
        e.preventDefault()
      }
    },

    doneTyping() {
      this.error = this.displayValue.indexOf("highlight") != -1;
      if (this.text.trim() !== "") {
        this.$refs.editable.classList.remove("placeholder");
      } else {
        this.$refs.editable.classList.add("placeholder");
      }
      this.$emit("doneTyping");
    },

    removeHighlights() {
      this.text = this.$refs.editable.innerHTML.replace(
        /<span class="highlight">(.*?)<\/span>/g,
        "$1"
      );
      this.$nextTick(() => {
        this.placeCaretAtEnd();
      });
    },

    placeCaretAtEnd() {
      const editableDiv = this.$refs.editable;
      editableDiv.selectionStart = editableDiv.selectionEnd = 10000;
      var range, selection;
      if (document.createRange) {
        //Firefox, Chrome, Opera, Safari, IE 9+
        range = document.createRange(); //Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(editableDiv); //Select the entire contents of the element with the range
        range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection(); //get the selection object (allows you to change selection)
        selection.removeAllRanges(); //remove any selections already made
        selection.addRange(range); //make the range you have just created the visible selection
      }
    },

    setNewVal(newVal, trigger = false) {
      const raw = newVal.replace(/<[^>]*>?/gm, '');

      let result = stringToSlug(raw).replace(/&nbsp;/gm, '');
      // Long words => required short period
      if (this.wordsToHighlight.length != 0) {
        const parts = result.split(/\s+/);
        for (let partIdx in parts) {
          if (partIdx > 0) {
            const regex = new RegExp(`\\s+(${parts[partIdx]})`, "g");
            result = result.replace(regex, '');
          } else {
            for (let word of this.wordsToHighlight) {
              const regex = new RegExp(`(${word})`, "g");
              result = result.replace(regex, '');
            }
          }
        }
      }

      this.$emit("processedValue", result.trim());
      this.$emit("rawValue", raw);
      if (trigger) {
        this.$emit("doneTyping");
      }
    },
  },

  computed: {
    displayValue: {
      get: function () {
        let result = stringToSlug(this.text)
        // Long words => required short period
        if (this.wordsToHighlight.length != 0) {
          const parts = result.split(/\s+/);
          for (let partIdx in parts) {
            if (partIdx > 0) {
              const regex = new RegExp(`\\s+(${parts[partIdx]})`, "g");
              result = result.replace(regex, '<span class="highlight"> $1</span>');
            } else {
              for (let word of this.wordsToHighlight) {
                const regex = new RegExp(`(${word})`, "g");
                result = result.replace(regex, '<span class="highlight">$1</span>');
              }
            }
          }
        }

        return result;
      },

      set: function () {},
    },
    text: {
      get: function () {
        return this.modelValue;
      },
      set: function (newVal) {
        this.setNewVal(newVal);
      },
    },
    wordsToHighlight() {
      if (!this.invalidateMessage) {
        return [];
      }
      return ["cong", "ty", "khach", "le", "san pham", "dich vu", "tnhh"];
    },
  },

  watch: {
    invalidateMessage() {
      this.setNewVal(this.text, true);
    },
  },
};
</script>

<style scoped>
.editable-input {
  border: 1px solid #ccc;
  outline: none;
  font-weight: 300;
  font-size: 13px;
  border-radius: 4px;
  border-color: rgb(229, 231, 235);
  line-height: normal;
  height: 2rem;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-collapse: collapse;
}

.editable-input .input {
  width: 100%;
  height: 100%;
  padding: 0rem 1rem;
  line-height: 2rem;
  outline: none !important;
}

.editable-input .search-btn {
  border: none;
  background: #f5f7fa;
  border-left: 0.5px solid #ccc;
  display: flex;
  margin: 0;
}

::v-deep(.highlight) {
  font-weight: 500;
  color: red;
  font-style: italic;
}
.alert {
  right: 2.25rem;
  top: 0.3rem;
  padding: 0.2rem;
  /* height: 0.5rem; */
  min-height: 0.5rem;
}
.placeholder::before {
  content: attr(data-placeholder);
  color: #999;
  pointer-events: none;
}

.input:focus {
  border: 0.5px solid var(--el-input-focus-border,var(--el-color-primary));
}
.input:focus::before {
  content: "";
}
</style>

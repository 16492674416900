<template>
  <el-tooltip class="item w-20" effect="dark" :placement="placement">
    <template #content>
      <div :class="{'w-80': lookupinfo.invoice_note}" v-html="lookupinfo.invoice_note"></div>
    </template>

    <div class="text-center">
      <i v-if="!lookupinfo || !lookupinfo.invoice_status" class="el-icon-question text-yellow-600" />
      <i v-else-if="lookupinfo.invoice_status == 2 || lookupinfo.invoice_status == 3" class="el-icon-warning text-red-600" />
      <i v-else class="el-icon-success text-green-600" />
    </div>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    lookupinfo: {},
    placement: {
      type: String,
      default: "right",
    },
  },
};
</script>

<style scoped>
i {
  font-size: 1.25rem;
}
</style>
